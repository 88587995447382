import moment from "moment-timezone";
import ReactGA from "react-ga4";

export const dateFormatter = (date) => {
  const formattedDate = moment(date, ["YYYY-M-D", "YYYY-MM-DD"]).format(
    "YYYY-MM-DD"
  );
  return formattedDate;
};

export const trackBookMeetingClick = (companyId) => {
  ReactGA.event({
    action: `Book_meeting_click?id=${companyId}`,
    category: "Button",
    label: "Book Meeting",
    value: companyId,
  });
};
