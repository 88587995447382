import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const AnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Capture the start time when the user lands on the page
    const startTime = new Date().getTime();

    // Send pageview event
    // ReactGA.send({ hitType: "pageview", page_path: location.pathname });
    const fullPath = location.pathname + location.hash;
    ReactGA.send({ hitType: 'pageview', page_path: fullPath });

    // Function to calculate and send engagement time when the user navigates away
    return () => {
      const endTime = new Date().getTime();
      const timeSpent = endTime - startTime; // Time in milliseconds
      const timeSpentInSeconds = Math.round(timeSpent / 1000);

      ReactGA.event({
        category: "Engagement",
        action: `top_${fullPath}`,
        label: fullPath,
        value: timeSpentInSeconds
      });

      console.log(`${timeSpentInSeconds}sec spent on ${fullPath}`)
    };
  }, [location]);

  return null;
};

export default AnalyticsTracker;
