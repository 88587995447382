// import dependencies
import { useContext, useEffect, useState } from "react";
//import files
import ConfirmationModal from "../../Modals/ConfirmationModal";
import ScoreModal from "../../Modals/ScoreModal";
import { getMethod, postMethod } from "../../../../utils/apiMethod";
import { toastError, toastSuccess } from "../../../../utils/Toasters";
import { Link, useLocation } from "react-router-dom";
import { StateContext } from "../../../Context/StateContext";
import ReadMore from "../../ReadMore";
import { BookMeeting, FollowUser, Score } from "../../icons/Icons";
import styles from "../../../../styles/modular css/TopTen.module.css"
import ReactPlayer from "react-player";
import Cookies from "js-cookie";
import { trackBookMeetingClick } from "../../../../utils/userAgent";

function TopTen({
  video,
  name,
  image,
  rating,
  employeeCount,
  description,
  id,
  followed,
  index,
  classes,
  fetchCardId,
  setShowScoreModal,
  setShowUnfollowModal,
  scored,
  meetingLink,
  categories,
  openModalHandler,
  setVideo,
}) {
  // state variables and functions

  const { setFollowStatusChanged } = useContext(StateContext);

  // const [showModal, fetchCardId] = useState(false);
  // const [showScoreModal, setShowScoreModal] = useState(false);
  const [status, setStatus] = useState(followed ? "Unfollow" : "Follow");

  const location = useLocation();

  useEffect(() => {
    setStatus(followed ? "Unfollow" : "Follow");
  }, [followed]);

  const handleFollwUnfollowApi = () => {
    // console.log("follow id : ", id)
    if (status === "Follow") {
      // setLoading(true);
      let fcmToken = Cookies.get("fcm");
      var raw = JSON.stringify({
        fcmToken: fcmToken || null,
      });
      postMethod(`users/followUnfollowCompany/${id}`,raw)
        .then((res) => {
          if (res?.status) {
            setStatus("Following");
            setTimeout(() => {
              setStatus("Unfollow");
            }, 1000);
            toastSuccess(res.message);

            setFollowStatusChanged(true);
          }
        })
        .catch((error) => {
          // setLoading(false);
          toastError(error.message);
          console.log("error.message: ", error.message);
        });
    } else if (status === "Unfollow") {
      fetchCardId(id);
      setShowUnfollowModal(true);
      document.body.style.overflowY = "hidden";
    }
  };

  return (
    <div
      className={`d-flex d-flex-column rounded-16 box-shadow1 border-white ${classes}`}
    >
      {/* Upper row */}

      <div className={`${styles["card"]} d-flex gap-2 font-12 p-3 h-full`}>
        <div
          className={`col-12 col-md-5 d-flex d-justify-center rounded-12 o-hidden ${
            video ? "" : "bg-image-not-found rounded-20"
          }`}
        >
          {video ? (
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setVideo(video);
                openModalHandler();
              }}
              className="p-relative"
            >
              <ReactPlayer
                width="100%"
                height="100%"
                url={video}
                alt="company video"
              />
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: "0",
                  left: "0",
                }}
                className="d-flex d-align-center d-justify-center"
              >
                <img
                  style={{
                    width: "144px",
                    height: "144px",
                    objectFit: "contain",
                  }}
                  width={144}
                  height={144}
                  className="cursor-pointer"
                  src="/assets/playIcon.svg"
                  alt="play-icon"
                />
              </div>
            </div>
          ) : (
            <img
              width="100%"
              height="100%"
              className="rounded-8"
              src="/assets/videoThumbnail.png"
              alt="company-default-thumbnail"
            />
          )}
        </div>

        <div className="col-12 col-md-7 d-flex d-flex-column d-justify-space-evenly gap-1 p-1">
          <Link
            state={{ from: location.pathname }}
            className="col-12 d-flex d-flex-column gap-1"
            to={`/companyProfile/${id}#Products`}
          >
            <div className="d-flex d-align-center gap-2">
              <div style={{ height: "57px" }}>
                <img
                  style={{ objectFit: "contain", width: "auto" }}
                  height="100%"
                  src={`${image ? image : "/assets/Image-Not-Found.png"}`}
                  alt={name}
                />
              </div>

              <span className="h4 f-700 text-blue text-left text-ellipsis-1">
                {name}
              </span>
              {/* <div className="d-flex d-align-center gap-1">
                <div className="font-16">
                  <b>
                    {employeeCount}
                    {employeeCount > 0 ? "+" : ""}
                  </b>
                </div>
                <div className="font-16"> Employees</div>
              </div> */}
            </div>
            <div className="d-flex d-flex-wrap gap-1">
              {categories &&
                categories?.slice(0, 6)?.map((item, index) => (
                  <div
                    key={index + 1}
                    className="d-flex gap-1 bg-light-sea-green rounded d-align-center d-justify-center p-1 border"
                  >
                    <div className="bg-sea-green rounded d-flex d-justify-center d-align-center">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="15" cy="15" r="15" fill="#1CBAAB" />
                        <g clip-path="url(#clip0_1033_6854)">
                          <path
                            d="M22.5 14.375C22.5 13.7596 22.1628 13.2281 21.6667 12.9396V8.95859C21.6667 8.73073 21.4849 8.125 20.8333 8.125C20.6479 8.125 20.4638 8.18698 20.313 8.30781L18.0987 10.0794C16.9865 10.9685 15.5901 11.4583 14.1667 11.4583H9.16667C8.24609 11.4583 7.5 12.2044 7.5 13.125V15.625C7.5 16.5456 8.24609 17.2917 9.16667 17.2917H10.0443C10.0081 17.5646 9.9875 17.8422 9.9875 18.125C9.9875 19.1607 10.2286 20.1393 10.6531 21.0141C10.7883 21.2924 11.0833 21.4583 11.3927 21.4583H13.3271C14.0055 21.4583 14.4128 20.6813 14.0016 20.1417C13.5745 19.5813 13.3206 18.8823 13.3206 18.125C13.3206 17.8357 13.3628 17.5576 13.4354 17.2917H14.1667C15.5901 17.2917 16.9865 17.7815 18.0984 18.6706L20.3128 20.4422C20.4604 20.5604 20.6439 20.6248 20.8331 20.625C21.482 20.625 21.6664 20.0318 21.6664 19.7917V15.8107C22.1628 15.5219 22.5 14.9904 22.5 14.375ZM20 18.0578L19.1393 17.3693C17.7331 16.2443 15.9667 15.625 14.1667 15.625V13.125C15.9667 13.125 17.7331 12.5057 19.1393 11.3807L20 10.6922V18.0578Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1033_6854">
                            <rect
                              width="15"
                              height="15"
                              fill="white"
                              transform="translate(7.5 7.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="h6 text-sea-green">{item?.name}</div>
                  </div>
                ))}
            </div>
            <p
              className={`h6 m-0 word-break text-grey-4 text-ellipsis-2 text-left`}
            >
              {description}
            </p>
          </Link>

          <div className="">
            <div className="row d-flex-column gap-1">
              <div className="d-flex gap-1 d-justify-center">
                <div className="col-6">
                  <button
                    onClick={handleFollwUnfollowApi}
                    className={`btn col-12 d-flex d-align-center gap-1 ${
                      followed ? "btn-white-thin" : "btn-aqua-thin"
                    }`}
                  >
                    {!followed && <FollowUser />}

                    {followed ? "Unfollow" : "Follow"}
                  </button>
                </div>

                <div className="col-6">
                  <button
                    // className={`btn btn-aqua-thin btn-disable-score col-12 d-flex d-align-center gap-1 ${
                    //   scored ? "cursor-disabled" : ""
                    // }`}
                    className={`btn btn-aqua-thin col-12 d-flex d-align-center gap-1 btn-disable-score`}
                    // onClick={() => scored ? "" : scoreModalHandler()}
                  >
                    <Score />
                    {scored ? "Scored" : "Score"}
                  </button>
                </div>
              </div>
              <div>
                <button
                  onClick={() => {
                    trackBookMeetingClick(id);
                    if (meetingLink) window.open(meetingLink, "_blank");
                    else
                      toastError(
                        "Sorry! No meeting link found for this company."
                      );
                  }}
                  className="btn btn-sea-green-thin col-12 d-flex d-align-center gap-1"
                >
                  <BookMeeting />
                  Book Meeting
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* lower row */}
    </div>
  );
}

export default TopTen;
