import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/mousewheel";
// import './Slider.css';
import { Pagination, Mousewheel } from "swiper/modules";
import { getMethod, postMethod } from "../utils/apiMethod";
import { toastError, toastSuccess } from "../utils/Toasters";
import { Link } from "react-router-dom";
import ScoreModal from "./general/Modals/ScoreModal";
import ConfirmationModal from "./general/Modals/ConfirmationModal";
import { StateContext } from "./Context/StateContext";
import Company from "./general/cards/dashboard/Company";
import Slide from "./general/cards/dashboard/TopTen";
import TopTen from "./general/cards/dashboard/TopTen";
import ModalWrapper from "./general/Modals/ModalWrapper";
import VideoModal from "./general/Modals/VideoModal";
import Cookies from "js-cookie";

const Slider = ({ companyId }) => {
  // state variables and functions
  const { setLoading } = useContext(StateContext);

  const { followStatusChanged, setFollowStatusChanged } =
    useContext(StateContext);

  // const [status, setStatus] = useState(followed ? "Unfollow" : "Follow");
 const [openModal, setOpenModal] = useState(false);
 const openModalHandler = () => setOpenModal((prev) => !prev);
  const [cardId, setCardId] = useState(null);
  const [showScoreModal, setShowScoreModal] = useState(false);
  const [showUnfollowModal, setShowUnfollowModal] = useState(false);
  const [video, setVideo] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [topTenCompanies, setTopTenCompanies] = useState([]);

  const handleModalYes = () => {
    // setLoading(true);
    let fcmToken = Cookies.get("fcm");
    var raw = JSON.stringify({
      fcmToken: fcmToken || null,
    });
    postMethod(`users/followUnfollowCompany/${cardId}`,raw)
      .then((res) => {
        if (res?.status) {
          setCardId("");
          setRefresh(true);
          document.body.style.overflowY = "unset";
          toastSuccess(res.message);
          setShowUnfollowModal(false);
          setFollowStatusChanged(true);
        }
      })
      .catch((error) => {
        // setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  };

  const handleModalNo = () => {
    setCardId("");
    setShowUnfollowModal(false)
    document.body.style.overflowY = "unset";
  };

  const handleScoreCancel = () => {
    setShowScoreModal(false);
    document.body.style.overflowY = "unset";
  };
  const handleScoreRateCompany = () => {
    setShowScoreModal(false);
    document.body.style.overflowY = "unset";
  };

  useEffect(() => {
    // setLoading(true);
    getMethod(`users/getTopTenCompanies`)
      .then((res) => {
        if (res?.status) {
          // setLoading(false)
          // console.log("res.data: ", res);
          if (res.data && res.data.length > 0) {
            let arr = res.data.slice(0, 5);
            setTopTenCompanies(arr);
            console.log(arr);
          }
        }
      })
      .catch((error) => {
        // setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, [followStatusChanged, refresh]);
  return (
    <div className="">
      <Swiper
        slidesPerView={1.2} // Show 1 full slide and part of the next slide
        spaceBetween={20} // Space between slides
        // pagination={{
        //   clickable: true,
        // }}
        mousewheel={true}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          991: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1400: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
        }}
        modules={[Mousewheel]}
        className="mySwiper h-full"
      >
        {topTenCompanies.map((company, index) => {
          return (
            <SwiperSlide style={{ height: "auto" }} key={index}>
              <TopTen
                name={company?.name}
                image={company?.thumbnailUrl}
                rating={company?.companyRating}
                description={company?.description}
                employeeCount={company?.employeeCount}
                id={company?.id}
                index={index}
                followed={company?.userFollowsCompany}
                classes={"h-full"}
                fetchCardId={setCardId}
                setShowScoreModal={setShowScoreModal}
                setShowUnfollowModal={setShowUnfollowModal}
                scored={company?.userHasScoredCompany}
                video={
                  company?.companyAllContent?.filter(
                    (item) => item.markedVideo == "featuredVideo"
                  )[0]?.videoLink
                }
                meetingLink={company?.meetingUrl}
                categories={company?.companyMarketCats?.map(
                  (item) => item?.marketCategoryDefined
                )}
                openModalHandler={openModalHandler}
                setVideo={setVideo}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>

      {showUnfollowModal && (
        <div>
          <ConfirmationModal onYes={handleModalYes} onNo={handleModalNo} />
        </div>
      )}
      {showScoreModal && (
        <div>
          <ScoreModal
            handleCancel={handleScoreCancel}
            handleRateCompany={handleScoreRateCompany}
            companyId={cardId}
            setRefresh={setRefresh}
          />
        </div>
      )}
      {openModal && (
        <ModalWrapper>
          <VideoModal
            url={video}
            closeHandler={() => setOpenModal(false)}
          ></VideoModal>
        </ModalWrapper>
      )}
    </div>
  );
};

export default Slider;
